import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    toggleAllCheckboxes(event) {
        const checkboxes = document.getElementsByName("quote[item_ids][]");

        checkboxes.forEach((i) => {
            if (!i.disabled) {
                i.checked = event.target.checked
            }
        });
    }

    async overridePremium(event) {
        event.preventDefault();
        const step = this._step(event);
        const quoteId = this._quoteId(event);
        if (document.getElementById("override-field").value !== null) {
            await this.stimulate("QuoteFlowReflex#override_premium", quoteId, step)
        }
        if (step === "step_five") {
            this.notifyPaymentMethodSelected(event);
        }
    }

    notifyPaymentMethodSelected(event) {
        const quoteId = this._quoteId(event);
        const selectedPaymentMethod = document.getElementById("payment-method-select").value;
        this.stimulate("QuoteFlowReflex#notify_payment_method_selected", quoteId, selectedPaymentMethod);
    }

    submit(event) {
        this.displayStepSinner();
        event.target.form.submit()
    }

    displayStepSinner() {
        document.getElementById("step-spinner").classList.remove("hidden")
    }

    _isPaymentMethodSelected() {
        const paymentMethodSelect = document.getElementById("payment-method-select");
        const selectedPaymentMethod = paymentMethodSelect.options[paymentMethodSelect.selectedIndex].value;
        return selectedPaymentMethod !== "";
    }

    _step(event) {
        return event.target.dataset.step;
    }

    _quoteId(event) {
        return event.target.dataset.quoteId;
    }
}
