import ApplicationController from "./application_controller";
export default class extends ApplicationController {
    static targets = ["waiRequestCard", "generateButton", "loadingButton", "waiResponseCard", "assessmentTab", "feedbackTab", "assessmentTabContent", "feedbackTabContent"];

    static values = { generateEndpoint: String, getEndpoint: String }

    initialize() {
        this.fetchExistingAssessment()
    }

    fetchExistingAssessment() {
        fetch(this.getEndpointValue)
            .then(async response => {
                if (response.status === 200) {
                    this.showcaseAssessmentResponse(await response.json());
                } else if (response.status === 404) {
                    if ((await response.json()).can_create_assessment) {
                        this.waiRequestCardTarget.style.display = "block";
                    }
                } else {
                    console.error('Unknown error fetching assessment:', response);
                }
            })
    }

    generateAssessment() {
        this.generateButtonTarget.style.display = "none";
        this.loadingButtonTarget.style.display = "flex";

        fetch(this.generateEndpointValue, {method: "POST"})
            .then(async response => {
                this.showcaseAssessmentResponse(await response.json());
            })
            .catch(error => {
                console.error('Error generating the assessment:', error);
            });
    }

    switchToAssessmentTab() {
        this.switchToTab("assessmentTab");
    }

    switchToFeedbackTab() {
        this.switchToTab("feedbackTab");
    }

    switchToTab(activeTab) {
        const inactiveClasses = "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group cursor-pointer"
        const activeClasses = "inline-flex items-center justify-center p-4 text-blue-400 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group cursor-pointer"

        const allTabs = ["assessmentTab", "feedbackTab"]

        const inactiveTabs = allTabs.filter((tab) => tab !== activeTab)

        inactiveTabs.forEach(tab => {
            this[tab + "ContentTarget"].style.display = "none";
            this[tab + "Target"].className = inactiveClasses;
        })
        this[activeTab + "ContentTarget"].style.display = "block";
        this[activeTab + "Target"].className = activeClasses
    }

    showcaseAssessmentResponse(response) {
        const { assessment, feedback, liked } = response

        const detailedResponseElement = document.getElementById("detailed-assessment");
        detailedResponseElement.innerHTML = assessment;

        let responseArr = assessment.split("Verdict:");
        let onlyUntilAUIDetail = responseArr[1].split("Aui Questions Detail:");
        let onlyVerdict = onlyUntilAUIDetail[0].trim();

        const simpleResponseElement = document.getElementById("simple-assessment");
        simpleResponseElement.innerHTML = onlyVerdict;

        if (feedback) {
            const feedbackElement = document.getElementById("feedback-field");
            feedbackElement.innerHTML = feedback;
        }

        if (liked != null) {
            const likedElement = document.getElementById((liked) ? "liked-true" :"liked-false");
            likedElement.checked = true;
        }

        this.waiRequestCardTarget.style.display = "none";
        this.waiResponseCardTarget.style.display = "block";
    }
}