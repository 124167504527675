import ApplicationController from "./application_controller";
export default class extends ApplicationController {

    static targets = ["retryButton"];

    static values = { endpoint: String }

    connect() {
        this.checkIntegrationResults()
    }

    checkIntegrationResults() {
        fetch(this.endpointValue)
            .then(response => response.json())
            .then(integrationResults => {
                const anyFailure = Object.values(integrationResults).some(didFinish => !didFinish)

                const resultMessage = Object.entries(integrationResults).reduce((accum, elem) => {
                    const [integration, didFinish] = elem
                    const result = didFinish ? "&#9989;" : "&#10060;"
                    return accum + integration + ": " + result + "\n"
                },"")

                const resultsElement = document.getElementById("wai-results");

                resultsElement.innerHTML = resultMessage;

                if (anyFailure) {
                    this.retryButtonTarget.style.display = "block"
                }
            })
            .catch(error => {
                console.error('Error fetching wai values:', error);
            });
    }
}