// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "components";

require("alpine-turbo-drive-adapter");
require("alpinejs");
require("stylesheets/styles.scss");

import CableReady from "cable_ready";

const enableAlpine = (options, fromEl, toEl) => {
  if (fromEl.__x) {
    window.Alpine.clone(fromEl.__x, toEl);
  }
  return true;
};

document.addEventListener("turbo:click", (event) => {
  // Add data element history = true to source element to update url
  if (event.srcElement.dataset["history"] !== undefined) {
    navigator.history.push(new URL(event.detail.url));
  }
});

// https://github.com/SimoTod/alpine-turbo-drive-adapter/issues/31
let turboFramesObserver;
document.addEventListener("turbo:load", () => {
  if (turboFramesObserver && turboFramesObserver.disconnect)
    turboFramesObserver.disconnect();
  // The setTimout gives a little bit of time to to render properly the newly injected HTML
  setTimeout(() => {
    turboFramesObserver = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === "childList") {
          Alpine.discoverUninitializedComponents(function (el) {
            Alpine.initializeComponent(el);
          });
        }
      }
    });

    // Gets all <turbo-frames> present in the page. Before they lazy-load their content.
    let turboFrames = document.querySelectorAll("turbo-frame");
    for (let turboFrame of turboFrames) {
      // Observes each frame, firing the MutationObserver callback upon any "childList" mutation
      turboFramesObserver.observe(turboFrame, {
        attributes: false,
        childList: true,
      });
    }
  }, 1000);
});

// https://gist.github.com/xtr3me/cf99f6673ebcca83f111abd41e9676bb
document.addEventListener('turbo:before-cache', function () {
  application.controllers.forEach(function (controller) {
    try {
      if (typeof controller.disconnect === 'function') {
        controller.disconnect()
      }
    } catch (_) {}
  })
})


CableReady.shouldMorphCallbacks.push(enableAlpine);
CableReady.operations.linkTo = ({ url }) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = url;
  link.click();
}
Turbo.start();
Rails.start();
ActiveStorage.start();

import "controllers";
