import { Turbo } from "@hotwired/turbo-rails"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["password",
                    "passwordError",
                    "confirmPassword",
                    "confirmPasswordError",
                    "tick",
                    "cross",
                    "phoneNumber",
                    "phoneNumberError"
                  ]

  reset(event) {
    event.preventDefault();
    Turbo.visit(location.href);
  }

  _perform(event) {
    this.stimulate("UserReflex#perform");
  }

  validate(event) {
    this.perform();
  }

  validate_field(event){
    let ele = event.target
    if(ele.checkValidity()){
      this.markFieldAsValid(ele);
    } else {
      this.markFieldAsInvalid(ele, "Can't be blank");
    }
  }

  markFieldAsValid(ele) {
    ele.classList.remove('border-red-500')
    document.querySelector('#' + ele.getAttribute('data-error-field')).innerHTML = ""
  }

  markFieldAsInvalid(ele, errorMessage) {
    ele.classList.add('border-red-500')
    document.querySelector('#' + ele.getAttribute('data-error-field')).innerHTML = errorMessage
  }

  auto_format_date(event){
    const inputValue = event.target.value
    const dateWrittenWithSlashes = /^\d{2}([/])\d{2}([/])\d{4}$/
    const dateWrittenWithNumbersOnly = /^\d{8}$/
    const dateWrittenWithMiddleScores = /^\d{2}([-])\d{2}([-])\d{4}$/

    if (inputValue.match(dateWrittenWithSlashes)) {
      event.target.value = inputValue.replaceAll("/", "-")
      return
    }
    if (inputValue.match(dateWrittenWithNumbersOnly)) {
      const month = inputValue.slice(0, 2);
      const day = inputValue.slice(2,4);
      const year = inputValue.slice(4);
      event.target.value = month + "-" + day + "-" + year
      return
    }
    if (inputValue.match(dateWrittenWithMiddleScores)) {
      this.markFieldAsValid(event.target)
      return
    }
    this.markFieldAsInvalid(event.target, "Follow format showed by placeholder")
  }

  validatePassword() {
    if (/[a-zA-Z]/.test(this.passwordTarget.value)) {
      this._validateCondition(0)
    } else {
      this._invalidateCondition(0)
    }

    if (/\d/.test(this.passwordTarget.value)) {
      this._validateCondition(1)
    } else {
      this._invalidateCondition(1)
    }

    if (/[*@!#%&()^~{}]+/.test(this.passwordTarget.value)) {
      this._validateCondition(2)
    } else {
      this._invalidateCondition(2)
    }

    if (this.passwordTarget.value.length > 10) {
      this._validateCondition(3)
    } else {
      this._invalidateCondition(3)
    }
  }

  matchPassword() {
    if (this.passwordTarget.value !== this.confirmPasswordTarget.value) {
      this.confirmPasswordErrorTarget.classList.remove("hidden")
    } else {
      if (!this.confirmPasswordErrorTarget.classList.contains("hidden")) {
        this.confirmPasswordErrorTarget.classList.add("hidden")
      }
    }
  }

  validatePhoneNumber() {
    if (/^(\+1)?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.phoneNumberTarget.value)) {
      if (!this.phoneNumberErrorTarget.classList.contains("hidden")) {
        this.phoneNumberErrorTarget.classList.add("hidden")
      }
    } else {
      this.phoneNumberErrorTarget.classList.remove("hidden")
    }
  }

  _toggleClass(classList, existingClass, newClass) {
    classList.remove(existingClass)
    classList.add(newClass)
  }

  _validateCondition(index) {
    if(this.passwordErrorTargets[index].classList.contains("text-red-400")) {
      this._toggleClass(this.passwordErrorTargets[index].classList, "text-red-400", "text-black-400")
      this.tickTargets[index].classList.remove("hidden")
      this.crossTargets[index].classList.add("hidden")
    }
  }

  _invalidateCondition(index) {
    if(this.passwordErrorTargets[index].classList.contains("text-black-400")) {
      this._toggleClass(this.passwordErrorTargets[index].classList, "text-black-400", "text-red-400")
      this.tickTargets[index].classList.add("hidden")
      this.crossTargets[index].classList.remove("hidden")
    }
  }
}
