import { Turbo } from "@hotwired/turbo-rails"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  reset(event) {
    event.preventDefault();
    Turbo.visit(location.href);
  }

  _perform() {
    this.stimulate("BrokerageReflex#perform");
  }

  validate() {
    this.perform();
  }
}
