import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the ImageModal Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  openModal(event) {
    const id = event.target.dataset.modalId;
    const imageShownId =  event.currentTarget.dataset.imageId;
    document.getElementById(id).classList.remove("hidden")
    document.getElementById(imageShownId).src = event.target.dataset.src
  }
  closeModal(event) {
    const id = event.currentTarget.dataset.modalId;
    const imageShownId =  event.currentTarget.dataset.imageId;
    document.getElementById(id).classList.add("hidden")
    document.getElementById(imageShownId).src = ""
  }
}
