import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["source"]

  connect() {}

  toggle() {
    if(this.sourceTarget.classList.contains('hidden'))
      this.sourceTarget.classList.remove('hidden')
    else 
      this.sourceTarget.classList.add('hidden')
  }
}
