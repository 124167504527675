import { Turbo } from "@hotwired/turbo-rails"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  reset(event) {
    event.preventDefault();
    Turbo.visit(location.href);
  }

  _perform(event) {
    this.stimulate("PolicyReflex#perform");
  }

  validate(event) {
    this.perform();
  }
  connect() {}

  submit(event) {
    this.displaySpinner();
    event.target.form.submit()
  }

  displaySpinner() {
    document.getElementById("spinner").classList.remove("hidden")
    document.getElementById("retry-payment").disabled = true
    document.getElementById("send-invoice").disabled = true
  }
}
