import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  connect() {
    super.connect();
    this.formTargets.forEach(element => {
      if (element.dataset.errors === "true") {
        const el = element.querySelector(".btn-save-item");
        el.disabled = true;
        el.classList.add("opacity-50");
        el.classList.add("cursor-not-allowed");
      }
    });
  }

  getFormValues() {
    const formData = new FormData(this.formTarget);
    const values = {};
    for (const [key, value] of formData.entries()) {
      if (key.startsWith("item[") && key.endsWith("]")) {
        values[key.substring(5, key.length - 1)] = value;
      }
    }
    return values;
  }

  validate(event) {
    const { name } = event.target;
    let field = name.split("[")[1].split("]")[0];
    this.stimulate("ItemForm#validate", field, this.getFormValues());
  }

  validatePhotos() {
    /* TODO: The following code is not the optimal way to validate the form
    * it's not necesarry to access the reflex, we could directly change the button styles here
    * When possible delete the validatePhotos method from the reflex and handle the validation here */
    this.stimulate("ItemForm#validatePhotos", this.getFormValues());
  }

  submit(event) {
    event.preventDefault();
    const photosEl = document.querySelector("[name='item[photos]']");
    const attachmentsEl = document.querySelector("[name='item[attachments]']");
    if (photosEl?.value && photosEl?.value !== "[]") {
      this.formTarget.appendChild(photosEl);
    }
    if (attachmentsEl?.value && attachmentsEl?.value !== "[]") {
      this.formTarget.appendChild(attachmentsEl);
    }
    this.formTarget.submit();
  }

  openFilePicker(event) {
    const fileInput = event.target.querySelector(".input-group-file");
    if (fileInput) {
      fileInput.click();
    }
  }

  addFileInput(event) {
    event.preventDefault();
    const template = this.formTarget.querySelector("template");
    const newInput = template.content.cloneNode(true);
    const el = this.formTarget.querySelector(".item-file-inputs");
    el.appendChild(newInput);
  }

  onFileInputChange(event) {
    const files = event.target.files;
    const label = event.target.parentElement.parentElement.querySelector(
      "span"
    );
    const el = this.formTarget.querySelector(".btn-upload-files");
    if (files && files.length > 0) {
      label.innerText = files[0].name.substring(0, 20).concat("...");
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  }

  toggleDisabled(fileInputs, disable) {
    const btnAdd = this.formTarget.querySelector(".btn-add-file");
    const btnUpload = this.formTarget.querySelector(".btn-upload-files");
    if (disable) {
      for (const input of fileInputs) {
        input.disabled = true;
      }
      [btnAdd, btnUpload].forEach((el) => {
        el.classList.add("opacity-50");
        el.classList.add("cursor-not-allowed");
      });
      return;
    }
    for (const input of fileInputs) {
      input.disabled = false;
    }
    [btnAdd, btnUpload].forEach((el) => {
      el.classList.remove("opacity-50");
      el.classList.remove("cursor-not-allowed");
    });
  }

  uploadFiles(event) {
    event.preventDefault();
    const fileInputs = this.formTarget.querySelectorAll(
      '.item-file-inputs input[type="file"]'
    );
    
    const files = [];
    for (const input of fileInputs) {
      if(input.files.length == 0) {
        continue
      }
      files.push(input.files[0]);
    }
    this.toggleDisabled(fileInputs, true);
    filestack_client.multiupload(files).then((files) => {
      if (files.some(file => !file.handle)) {
        // TODO: validate best validation in terms of UX
        alert("There was a problem saving your image. Please try again later. You can still save your item by clicking save below. If this problem persists, please contact brokers@wax.insure")
      } else {
        this.renderPreview(files);
        this.toggleDisabled(fileInputs, false);
      }
      this.validatePhotos()
    });
  }

  deleteFile(event) {
    // TODO: disable button while deleting
    event.preventDefault();
    const fileHandle = event.target.dataset.fileHandle;
    if (fileHandle) {
      const policy = document.querySelector("[name='fs_delete_policy']").value;
      const signature = document.querySelector("[name='fs_delete_signature']")
        .value;
      filestack_client
        .remove(fileHandle, { policy, signature })
        .then((_) => {
          const el = this.formTarget.querySelector(`#id-${fileHandle}`);
          el.remove();
          const previousFiles = this.getUploadedFiles();
          const newFiles = previousFiles.filter(
            (file) => file.handle !== fileHandle
          );
          this.setUploadedFiles(newFiles);
        })
        .catch((err) => {
          console.error("An error occured", err);
        });
    }
  }

  renderPhotoTemplate({ url, name, handle }) {
    return `
    <div class="flex m-4" id="id-${handle}">
      <div class="image-preview border-solid border px-2 py-4">
        <button data-action="click->item-form#deleteFile" data-file-handle="${handle}"
          class="delete-image absolute border rounded-sm	bg-gray-50 w-6 h-6 ml-32 flex items-center justify-center">
          <svg 
            style="pointer-events: none;"
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path fill-rule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
          </svg>
        </button>
        <img src="${url}" alt="${name}" class="w-40 h-40" />
      </div>
    </div>
    `;
  }

  renderAttachmentLine({ url, name, handle }) {
    return `
      <li class="px-4 py-4 border-b border-gray-200 w-full rounded-t-lg h-12 leading-4 text-sm" id="id-${handle}">
      <span class="inline-block float-left mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
          fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
          <path
            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
          <path
            d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
        </svg></span>
      ${name}
      <button data-action="click->item-form#deleteFile" data-file-handle="${handle}" class="inline-block ml-2">
        <svg
          style="pointer-events: none;"
          xmlns="http://www.w3.org/2000/svg"
          width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
        </svg>
      </button>

      <button class="float-right" onclick="event.preventDefault(); window.open('${url}')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
          viewBox="0 0 16 16">
          <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
          <path
            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
      </button>
    </li>
    `;
  }
  renderAttachmentTemplate(files) {
    const templateString = files.map(this.renderAttachmentLine).join("");
    return ` 
      <div class="flex mt-4">
        <ul class="bg-white rounded-lg border border-gray-200 text-gray-900" style="width: 26rem">
          ${templateString}
        </ul>
      </div>
    `;
  }

  renderPreview(files) {
    const filePickers = this.formTarget.querySelectorAll(".item-file-input");
    for (const picker of filePickers) {
      picker.remove();
    }
    const el = this.formTarget.querySelector(".file-preview");
    el.classList.remove("hidden");
    if (this.formTarget.name === "photos") {
      for (const file of files) {
        const template = this.renderPhotoTemplate(file);
        el.insertAdjacentHTML("beforeend", template);
      }
    } else if (this.formTarget.name === "attachments") {
      let template;
      const attachmentEl = el.querySelector("ul");
      // this check is done to prevent the attachment list from being rendered twice
      if (attachmentEl) {
        for (const file of files) {
          template = this.renderAttachmentLine(file);
          attachmentEl.insertAdjacentHTML("beforeend", template);
        }
      } else {
        template = this.renderAttachmentTemplate(files);
        el.insertAdjacentHTML("beforeend", template);
      }
    }
    const btnUpload = this.formTarget.querySelector(".btn-upload-files");
    btnUpload.classList.add("hidden");
    const previousFiles = this.getUploadedFiles();
    const newFiles = previousFiles.concat(files);
    this.setUploadedFiles(newFiles);
  }

  getUploadedFiles() {
    const el = this.formTarget.querySelector(
      `input[type='hidden'][name='item[${this.formTarget.name}]']`
    );
    return JSON.parse(el.value);
  }

  setUploadedFiles(value) {
    const el = this.formTarget.querySelector(
      `input[type='hidden'][name='item[${this.formTarget.name}]']`
    );
    el.value = JSON.stringify(value);
  }
}
