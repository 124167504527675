import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.displayDropPane();
  }

  displayDropPane() {
    if (document.getElementById("policy_document_drop_pane").children.length === 0) {
      window.filestack_client.picker({
        onUploadDone: (data) => {
          document.getElementById('filename').value =
              data.filesUploaded[0].filename;
          document.getElementById('size').value =
              data.filesUploaded[0].size;
          document.getElementById('type').value =
              data.filesUploaded[0].mimetype;
          document.getElementById('handle').value =
              data.filesUploaded[0].handle;
          document.getElementById('url').value =
              data.filesUploaded[0].url;
          document.getElementById("new_policy_document").submit();
        },
        accept: ['.pdf', 'text/*'],
        fromSources: ['local_file_system'],
        container: 'policy_document_drop_pane',
        displayMode: 'dropPane',
        maxSize: 524288000, //500MB
        maxFiles: 1,
        dropPane: {
          overlay: false,
          customText: `Upload document or drag and drop. 
        PDF, Doc, Docx up to 500MB`,
        }
      }).open();
    }
  }
}
