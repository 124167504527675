import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    deleteUserIdentityDocument(event) {
        const inputName = event.currentTarget.dataset.inputName
        this.stimulate("ImageUploader#delete_user_identity_document", event.currentTarget.dataset.imageContainerId, event.currentTarget.dataset.pickerContainerId, event.currentTarget.dataset.handle)
            .then(() => {
                const inputs = document.querySelectorAll(`[name^='${inputName}']`)
                inputs.forEach((input) => {
                    input.value = ""
                    input.disabled = true
                });
            });
    }

    deleteMedia(event) {
        const inputName = event.currentTarget.dataset.inputName
        this.stimulate("ImageUploader#delete_media", event.currentTarget.dataset.imageContainerId, event.currentTarget.dataset.pickerContainerId, event.currentTarget.dataset.handle)
            .then(() => {
                const inputs = document.querySelectorAll(`[name^='${inputName}']`)
                inputs.forEach((input) => {
                    input.value = ""
                    input.disabled = true
                });
            });
    }

    deleteAttachment(event) {
        const inputName = event.currentTarget.dataset.inputName
        this.stimulate("ImageUploader#delete_attachment", event.currentTarget.dataset.imageContainerId, event.currentTarget.dataset.pickerContainerId, event.currentTarget.dataset.handle)
            .then(() => {
                const inputs = document.querySelectorAll(`[name^='${inputName}']`)
                inputs.forEach((input) => {
                    input.value = ""
                    input.disabled = true
                });
            });
    }
}
